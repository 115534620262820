import validate from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/middleware/auth.global.ts";
import set_45dm_45favicon_45global from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/middleware/set-dm-favicon.global.ts";
import manifest_45route_45rule from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  set_45dm_45favicon_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}